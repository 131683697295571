import { STORAGE_REF } from "../Constants";
import { blobFromURL } from "../utility/File";

// TODO: support multiple exts, currently only supporting jpg, best as compression so save space for now
export default function FirebaseImage(src, uploadedSrc, blob) {
    let _this = this;

    function _generateBlob() {
        return blobFromURL(_this.uploadedSrc);
    }

    function _generateLocalSrc() {
        return _this.blob && window.URL.createObjectURL(_this.blob);
    };

    this.revokeLocalSrc = () => {
        window.URL.revokeObjectURL(this.src);
    };

    this.isUploaded = () => {
        return this.uploadedSrc;
    };

    this.blob = blob;
    this.uploadedSrc = uploadedSrc;
    this.src = src || _generateLocalSrc();

    this.hasImage = () => {
        return this.blob || this.src || this.uploadedSrc;
    };

    this.generateLocalImage = () => {
        return new Promise(
            (resolve, reject) => {
                if (this.src) {
                    resolve(this.src);
                    return;
                }

                if (this.blob) {
                    reject("Blob exists");
                    return;
                }

                if (!this.uploadedSrc) {
                    reject("No uploaded image src");
                    return;
                }

                return _generateBlob()
                    .then(
                        (result) => {
                            this.blob = result;
                            this.src = _generateLocalSrc();
                            resolve(this.src);
                        }
                    ).catch((error) => reject(error));
            }
        );
    }

    // usually rowID & path prefix has userID/objectID/images/id.ext
    this.uploadToFirebase = (pathPrefix, index) => {
        const imagesRef = STORAGE_REF.child(`${ pathPrefix }/images/${ index }.jpg`);

        return imagesRef.put(this.blob)
            .then(
                (snapshot) => {
                    return snapshot.ref.getDownloadURL()
                }
            )
            .then(
                (downloadURL) => {
                    this.uploadedSrc = downloadURL;
                    
                    return downloadURL;
                }
            );
    };

}

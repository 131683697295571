import { snapshotDataToListRow } from "./ListRow";
import { snapshotDataToListResponse } from "./ListResponse";

// make private variables with _, & use setters & getters for methods that require changing
export default function List(comparison, created, isTemporary=false, isDeleted=false, key, modified=new Date(), name, ownerID, responses=[], rows=[]) {
    this.comparison = comparison;
    this.created = created;
    this.isTemporary = isTemporary;
    this.isDeleted = isDeleted;
    this.key = key;
    this.modified = modified;
    this.name = name;
    this.ownerID = ownerID;
    this.responses = responses;
    this.rows = rows;

    this.hasResponses = () => {
        return this.responses && this.responses.length > 0;
    };

    this.toFirebaseObject = () => {
        let responses = {};

        for (let i = 0; i < this.responses.length; ++i) {
            const response = this.responses[i];
            
            responses[response.userID] = response.toFirebaseObject();
        }

        return {
            comparison: this.comparison,
            created: this.created,
            list: this.rows.map(
                (row) => row.toFirebaseObject()
            ),
            modified: this.modified,
            name: this.name,
            responses: responses,
            isTemporary: this.isTemporary,
            isDeleted: this.isDeleted
        };
    };

    this.generateFirebaseImagePathPrefix = () => {
        return `users/${ this.ownerID }/${ this.key }`;
    };
}

export const snapshotDataToList = (data, key, ownerID) => {
    const rows = data.list.map(
        (row) => snapshotDataToListRow(row)
    );

    let responses = [];
    if (data.responses) {
        const keys = Object.keys(data.responses);
        responses = keys.map(
            (key) => snapshotDataToListResponse(data.responses[key], key)
        );
    };

    return new List(data.comparison, data.created, data.isTemporary, data.isDeleted, key, data.modified, data.name, ownerID, responses, rows);
};

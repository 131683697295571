export const randomID = (length = 32) => {
    let result = "";
    let characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (let i = 0; i < length; ++i) {
        const index = Math.floor(Math.random() * characters.length);
        result += characters.charAt(index);
    }

    return result;
};

import React from "react";

import { Switch, Route } from "react-router-dom";

import AggregatedResults from "../../screens/aggregated_results/AggregatedResults";
import CreateList from "../../screens/create_list/CreateList";
import MyLists from "../../screens/my_lists/MyLists";
import Profile from "../../screens/profile/Profile";
import Results from "../../screens/results/Results";
import RunList from "../../screens/run_list/RunList";

import NotFound from "../../screens/not_found/NotFound";

const AuthenticatedRouter = () => (
    <Switch>
        <Route exact path="/" component={ CreateList } />
        <Route exact path="/edit/:uid/:lid" component={ CreateList } />
        <Route exact path="/profile" component={ Profile } />
        <Route exact path="/my-lists" component={ MyLists } />
        <Route exact path = "/run-list/:uid/:lid" component={ RunList } />
        <Route exact path="/results/:uid/:lid/:ruid" component={ Results } />
        <Route exact path="/aggregated-results/:uid/:lid" component={ AggregatedResults } />
        <Route exact path="*" component={ NotFound } />
    </Switch>
);

export default AuthenticatedRouter;

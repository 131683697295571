export const isEmpty = (string) => {
    return !string || string.length === 0 || !string.trim();
};

export const isBlank = (string) => {
    return !string || /^\s*$/.test(string);
};

export const containsCharacter = (string, char) => {
    return string.indexOf(char) >= 0;
};

export const containsSpace = (string) => {
    return containsCharacter(string, ' ');
};


import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { getList, saveListWithUpdatedProperties } from "../../backend/Database";
import { withStyles } from '@material-ui/core/styles';

import { Link } from "react-router-dom";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Share from "@material-ui/icons/Share";
import Slide from "@material-ui/core/Slide";
import Snackbar from "@material-ui/core/Snackbar";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import Table from "@material-ui/core/Table";
import TableHead from '@material-ui/core/TableHead';
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

import Alert from "../../components/alert/Alert";
import UserError from "../../components/user_error/UserError";
import { isEmpty } from "../../utility/String";

import { CopyToClipboard } from "react-copy-to-clipboard";

import { generateWebLink } from "../../utility/Link";

import "./Results.css";

const Transition = React.forwardRef(
    function Transition(props, ref) {
        return <Slide direction="up" ref={ ref } { ...props } />
    }
);

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.primary4Color,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

// TODO: use lists = [list] for useEffect
function Results(props) {

    const user = useSelector(state => state.auth.user);

    const [alertSeverity, setAlertSeverity] = useState("");
    const [userError, setUserError] = useState({});
    const [list, setList] = useState({});
    const [listName, setListName] = useState("");
    const [message, setMessage]   = useState("");
    const [isSaveDialogOpen, setIsSaveDialogOpen] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const [rows, setRows] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const runLink = generateWebLink(list, false, "run-list");
    // copy the current link in the browser, more universal style
    const resultsLink = window.location.href;
    
    useEffect(
        () => {
            // user id
            const userID = props.match.params.uid;
            const listID = props.match.params.lid;
            const responseUserID = props.match.params.ruid;

            if (!userID || !listID || !responseUserID) {
                // TODO: display error to user, with redirect link
                setUserError(
                    {
                        title: "URL is invalid",
                        message: "Missing either, user id, list id, or response id"
                    }
                );
                return;
            }

            getList(userID, listID)
                .then(
                    (list) => {
                        // TODO: If deleted then show error
                        if (!list || list.isDeleted) {
                            setUserError(
                                {
                                    title: "List is invalid",
                                    message: "It doesn't exist, is deleted, or is corrupted"
                                }
                            );
                            return;
                        }
                        setList(list);

                        const filtered = list.responses.filter(
                            (response) => response.userID === responseUserID
                        );
                        if (filtered.length === 0) {
                            setUserError(
                                {
                                    title: "Invalid URL",
                                    message: "No response for given id"
                                }
                            );
                            return;
                        }

                        const rows = list.rows;
                        const response = filtered[0];
                        const orderedIndexList = response.orderedIndexList;
                        const orderedRows = [];

                        // orders the rows in the order of the response
                        for (let i = 0; i < orderedIndexList.length; ++i) {
                            const index = orderedIndexList[i];
                            
                            orderedRows.push(rows[index]);
                        }

                        setRows(orderedRows);
                    }
                )
                .catch(
                    (error) => {
                        // TODO: exit here
                        console.error(error);
                    }
                );
        }, []
    );

    const errorSnackbar = (message) => {
        setMessage(message);
        setAlertSeverity("error");
        setSnackbarOpen(true);
    }

    const successSnackbar = (message) => {
        setMessage(message);
        setAlertSeverity("success");
        setSnackbarOpen(true);
    }

    const handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway")
            return;

        setSnackbarOpen(false);
    }

    const handleAlertClose = (event, reason) => {
        if (reason === "clickaway")
            return;

        setSnackbarOpen(false);
    }

    const handleCopy = () => {
        successSnackbar("copied link to clipboard");
    };

    // TODO: decide if pass in name for being universal or use state instead
    // TODO: decide if need validation class, or have general validation functions
    // where used multiple times?
    // PERHAPS: allow prefix of spaces, doesn't seem bad, only if ALL spaces, don't allow
    const validateListName = () => {
        return !isEmpty(listName) && listName.length > 0;
    }

    const handleSaveDialogClose = () => {
        setListName("");
        setIsSaveDialogOpen(false);
    }

    // TODO: add spinner
    const handleSaveDialogSave = () => {
        if (!validateListName()) {
            errorSnackbar("Please enter a name for the list");
            return;
        }

        const copiedList = list;

        copiedList.name = listName;
        copiedList.isTemporary = false;

        setList(copiedList);

        setIsSaveDialogOpen(false);
        saveListWithUpdatedProperties(copiedList)
            .then(
                () => {
                    successSnackbar(`Saved ${ listName } successfully`);
                    setIsSaved(true);
                }
            )
            .catch(
                (error) => {
                    errorSnackbar("failed to save list");
                }
            );
    };

    const handleListNameChange = (event) => {
        // TODO: validate list name perhaps on save
        setListName(event.target.value);
    }

    const handleSave = () => {
        setIsSaveDialogOpen(true);
    };

    if (userError && userError.title && userError.message) {
        return (
            <div>
                <UserError
                    title={ userError.title }
                    message={ userError.message } />
            </div>
        );
    }

    // saveListWithUpdatedProperties

    // TODO: below I have uploaded src, use that to display image as is being done in the list editor, except it is uploaded src
    // should be much easier than before
    return (
        <div className={ `center` }>
            { /* Save Dialog Start */ }
            <Dialog
                open={ isSaveDialogOpen }
                keepMounted
                TransitionComponent={ Transition }
                onClose={ handleSaveDialogClose }>
                <DialogTitle>
                    Choose a name for your list
                </DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        fullWidth
                        id="name"
                        label="Form Name"
                        margin="dense"
                        type="text"
                        onChange={ handleListNameChange }
                        value={ listName } />
                </DialogContent>
                <DialogActions>
                    <Button onClick={ handleSaveDialogClose } color="primary">
                        Cancel
                    </Button>
                    <Button onClick={ handleSaveDialogSave } color="secondary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>

            { /* Save Dialog End */ }
            <Typography variant="h4" className={ `title result-title container` }>
                Results
            </Typography>
            <Typography variant="h5" className={ `sub-title container` }>
                Which one is {list.comparison}?
            </Typography>

            
            <Paper className={ `container center` } elevation={ 6 }>
                <Grid className={ `table-container` } container alignItems="flex-start">
                    <Table className={ `table` }>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Rank</StyledTableCell>
                                <StyledTableCell>Item</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                rows.map(
                                    (row, index) => {
                                        return (
                                            <TableRow className="table-row" key={index}>
                                                
                                                <StyledTableCell className="table-index">
                                                    { index + 1 }
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    { row.text }
                                                    <br />
                                                    { row && row.image && <img src={ row.image.uploadedSrc } /> }
                                                </StyledTableCell>
                                            </TableRow>
                                        )
                                    }
                                )
                            }
                        </TableBody>
                    </Table>
                </Grid>
            </Paper>
            <Grid container spacing={ 4 } alignItems="flex-end" className="list-panel">
                { user &&
                    <Grid item>
                        <Button component={ Link } to="/my-lists" variant="text" color="primary">
                            My Lists
                        </Button>
                    </Grid>
                }
                { list.isTemporary && !isSaved && user && list && user.uid === list.ownerID &&
                    <Grid item>
                        <Button variant="text" color="primary" onClick={ handleSave }>
                            Save
                        </Button>
                    </Grid>
                }
                <Grid item>
                    <Button component={ Link } to={ runLink } variant="text" color="secondary">
                        Restart
                    </Button>
                </Grid>
                <Grid className={ `share-button` } item>
                    <CopyToClipboard onCopy={ handleCopy } text={ resultsLink } >
                        <IconButton tooltip="Hide" className={ `icon-button` }>
                            <Share color="primary" />
                        </IconButton>
                    </CopyToClipboard>
                </Grid>
            </Grid>
            <Snackbar open={ snackbarOpen } autoHideDuration={ 2500 } onClose={ handleSnackbarClose} >
                <Alert onClose={ handleAlertClose } severity={ alertSeverity }>
                    { message }
                </Alert>
            </Snackbar>
        </div>
    );
    
}

export default Results;

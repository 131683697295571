import React from "react";

import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { CookiesProvider } from "react-cookie";
import { withRouter } from "react-router-dom";

import Root from "./Root";
import "./App.css";

// TODO: generate better theme, perhaps have themeing folder?
const theme = createMuiTheme(
    {
        palette: {
            "primary1Color": "#ffffff",
            "primary2Color": "#546e7a",
            "primary3Color": "#43a047",
            "primary4Color": "#3f51b5"
        }
    }
);

function App() {
    return (
        <CookiesProvider>
            <ThemeProvider theme = { theme }>
                <Root />
            </ThemeProvider>
        </CookiesProvider>
    )
};

export default withRouter(App);

import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(
    theme => (
        {
            button: {
                color: "white",
                fontWeight: "bolder"
            },
            rightSide: {
                marginLeft: "auto"
            },
            menuButton: {
                marginRight: theme.spacing(2),
            },
            root: {
                flexGrow: 1,
            },
            sendFeedback: {
                textDecoration: "none",
                marginLeft: "auto"
            },
            title: {
                flexGrow: 1,
            },
        }
    )
);

export default function AuthenticatedAppBar(props) {
    const classes = useStyles();

    return (
        <AppBar position="static">
            <Toolbar>
                <Button className={ classes.button } component = { Link } to = "/">
                    Prioritizer
                </Button>
                <div className={ classes.rightSide }>
                    <Button className={ classes.button } component = { Link } to = "/my-lists">
                        My Lists
                    </Button>
                    <Button className={ classes.button } component = { Link } to = "/profile">
                        { props.displayName }
                    </Button>
                </div>
            </Toolbar>
        </AppBar>
    );
}

import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Snackbar from "@material-ui/core/Snackbar";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";

import { deleteList, getLists } from "../../backend/Database";

import Alert from "../../components/alert/Alert";
import MyListsRow from "../../components/my_lists_row/MyListsRow";

import "./MyLists.css";

function MyLists() {
    const user = useSelector(state => state.auth.user);

    const [alertSeverity, setAlertSeverity] = useState("");
    const [lists, setLists] = useState([]);
    const [message, setMessage]   = useState("");
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    // TODO: get lists from user, also check if user is the one that is logged in

    useEffect(
        () => {
            // user id
            getLists(user.uid)
                .then(
                    (lists) => {
                        const activeLists = lists.filter(
                            (list) => !list.isDeleted && !list.isTemporary
                        );
                        setLists(activeLists);
                    }
                )
                .catch(
                    (error) => {
                        console.error(error);
                    }
                )
        }, []
    )

    const errorSnackbar = (message) => {
        setMessage(message);
        setAlertSeverity("error");
        setSnackbarOpen(true);
    }

    const successSnackbar = (message) => {
        setMessage(message);
        setAlertSeverity("success");
        setSnackbarOpen(true);
    }

    const handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway")
            return;

        setSnackbarOpen(false);
    }

    const handleAlertClose = (event, reason) => {
        if (reason === "clickaway")
            return;

        setSnackbarOpen(false);
    }

    const handleCopy = () => {
        successSnackbar("copied to clipboard");
    }

    // TODO: fix potential async problem when deleted clicked twice really fast, will create two copies of the list
    // & if the first happens too slowly, then the first lists copy will become lists with the second list included
    // potentially create queueing system
    const handleDelete = (key) => {
        const listsCopy = lists.slice();
        const index = listsCopy.findIndex(
            (list) => list.key === key
        )

        if (index === -1) {
            errorSnackbar("unable to delete list");
            return;
        }

        const list = listsCopy.splice(index, 1)[0];
        deleteList(list)
            .then(
                () => {
                    successSnackbar("successfully deleted");
                    setLists(listsCopy);
                }
            )
            .catch(
                (error) => {
                    console.log("error", error);
                    errorSnackbar("unable to delete list");
                }
            )
    }

    return (
        <div>
            { /* My Lists Start */ }
            <div className={ `center my-lists-container` }>
                <Typography className={ `center title` } variant="h4">
                    Active Lists
                </Typography>

                <Paper elevation={ 6 }>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        Title
                                    </TableCell>
                                    <TableCell>
                                        Responses
                                    </TableCell>
                                    <TableCell>
                                        Link
                                    </TableCell>
                                    <TableCell>
                                        Tools
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                { lists &&
                                    lists.map(
                                        (list) => {
                                            return (
                                                <MyListsRow list={ list } onCopy={ handleCopy } onDelete={ handleDelete } key={ list.key } uid={ user.uid }/>
                                            )
                                        }
                                    )
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </div>
            <Grid container spacing={ 4 } alignItems="flex-end" className="list-panel">
                <Grid item>
                    <Button component={ Link } to="/" variant="text" color="primary">
                        Create New List
                    </Button>
                </Grid>
            </Grid>

            <Snackbar open={ snackbarOpen } autoHideDuration={ 2500 } onClose={ handleSnackbarClose} >
                <Alert onClose={ handleAlertClose } severity={ alertSeverity }>
                    { message }
                </Alert>
            </Snackbar>
            { /* My Lists End */ }
        </div>
    )

}

export default MyLists;

import { AUTH, USERS_REF } from "../Constants";

export const resetPassword = (email) => {
    return AUTH.sendPasswordResetEmail(email);
}

export const sendVerificationEmail = () => {
    return new Promise(
        (resolve, reject) => {
            if (!AUTH.currentUser || AUTH.currentUser.emailVerified) {
                reject(new Error("email already verified"));
                return;
            }

            return AUTH.currentUser.sendEmailVerification();
        }
    );
};

export const signUp = (email, password, name) => {
    return AUTH.createUserWithEmailAndPassword(email, password)
        .then(
            (user) => {
                return AUTH.currentUser.updateProfile(
                    {
                        displayName: name
                    }
                ).then(
                    () => {
                        return sendVerificationEmail();
                    }
                );
            }
        );
};

export const signIn = (email, password) => {
    return AUTH.signInWithEmailAndPassword(email, password);
};

export const socialSignIn = (provider) => {
    return new Promise(
        (resolve, reject) => {
            try {
                AUTH.signInWithPopup(provider).then(
                    (result) => {
                        if (result && result.user) {
                            const user = result.user;
                            const { uid, email, displayName } = user;
                            resolve(
                                USERS_REF.child(uid).update(
                                    {
                                        email: email,
                                        name: displayName,
                                    }
                                )
                            );
                        }
                    }
                )
            } catch(error) {
                reject(error);
            }
        }
    );
};

export const signOut = () => {
    return AUTH.signOut();
};

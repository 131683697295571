function Rank(avg = 0, nuanceAvg = 0, index) {
    this.avg = avg;
    this.nuanceAvg = nuanceAvg;
    this.index = index;
}

export default function Ranker(list) {
    // TODO: make copy of list being passed in
    this._list = list;
    // TODO: populate output variable using logic from aggregated results comment
    this._output = [];
    this.rankedRows = [];

    let min = Number.MAX_SAFE_INTEGER;
    let max = Number.MIN_SAFE_INTEGER;
    let _this = this;

    // returns 1 if range is 0 to prevent /0 issues
    function _range() {
        const range = (max - min);
        
        if (range === 0)
            return 1;
        
        return range;
    }

    _rankItems();

    this.output = function() {
        return this._output;
    }

    function _rankItems() {
        const length = _this._list.rows.length;
        const lastIndex = length - 1;
        const responses = _this._list.responses;
        const responsesLength = responses.length;

        _this.rankedRows = _this._list.rows.map(
            (_, index) => new Rank(0, 0, index)
        );

        // go through each response
        for (let i = 0; i < responsesLength; ++i) {
            // get response
            const response = _this._list.responses[i];
            // go through each ranked row
            for (let j = 0; j < length; ++j) {
                // get index of item I want to be in place j
                const index = response.orderedIndexList[j];
                // get row of item using index
                const rankedRow = _this.rankedRows[index];

                // add the index j where I want to place item
                rankedRow.avg += j;

                _this.rankedRows[index] = rankedRow;
            }
        }
        
        for (let i = 0; i < length; ++i) {
            const rankedRow = _this.rankedRows[i];
            
            rankedRow.avg /= responsesLength;
            
            if (min > rankedRow.avg) {
                min = rankedRow.avg;
            }

            if (max < rankedRow.avg) {
                max = rankedRow.avg;
            }
        }
        for (let i = 0; i < length; ++i) {
            const rankedRow = _this.rankedRows[i];
            
            rankedRow.nuanceAvg = ((rankedRow.avg - min) / _range()) * lastIndex;
            _this.rankedRows[i] = rankedRow;
        }
        
        // Don't need index with nuance average in return as it is already in order
        // go through each response
        for (let i = 0; i < responsesLength; ++i) {
            // go through each item in the list
            for (let j = 0; j < length; ++j) {
                // get index of response list, index is of item in the original list
                const index = _this._list.responses[i].orderedIndexList[j];
                
                // using j as position of where we want to place item, with index referencing
                // item in the original list, use index to get item in ranked rows
                // calculated distance from nuance average
                const val = j - _this.rankedRows[index].nuanceAvg;
                
                // std deviation, summation aspect, sum up squared "distances"
                _this._list.responses[i].stdDev += (val * val);
            }

            // divide std deviation by population amount n, in this chase being length of list
            _this._list.responses[i].stdDev /= length;
        }
        
        // TODO: add table order, using if !arr[i], then def, & push, etc
        _this._output[0] = _this.rankedRows
            .sort(
                (a, b) => a.nuanceAvg - b.nuanceAvg
            ).map(
                (rank) => {
                    return {
                        rank: rank,
                        row: _this._list.rows[rank.index]
                    };
                }
            );

        _this._list.responses.sort(
            (a, b) => a.stdDev - b.stdDev
        );

        // for loop algorithm as each response has indexes that indicate where the order
        // you chose, i.e arr[pos you want] = index of item in original list
        // this algorithm makes it so that we match the indexes with the sorted items
        // so if we chose positon 4 for a, and a is the 1st row when sorted
        // we want to display 4 instead of our indexes, so we must match the indexes of
        // the response list with the items in the sorted row list
        // go through each response
        for (let i = 0; i < _this._list.responses.length; ++i) {
            let output = {
                arr: [],
                response: _this._list.responses[i]
            };
            // go throught each ranked row 
            for (let j = 0; j < length; ++j) {
                // get index of ranked row, index is of item in the original list 
                const index = _this.rankedRows[j].index;
                // go through each item in the response list, position of item is where
                // you want the item to be, the value is the index of what item you want
                // in that position, i.e arr[pos] = index of original list
                for (let k = 0; k < length; ++k) {
                    // compare each responses list item index to index of ranked row
                    if (_this._list.responses[i].orderedIndexList[k] === index) {
                        // if matches, then push what the user chose for the position
                        // for that item (index of item technically)
                        output.arr.push(k);
                    }
                }
            }
            _this._output[i + 1] = output;
        }
    }

}
import {
    SUCCESS,
    SIGN_IN,
    SIGN_OUT
} from "../../Constants";

const initialState = {
    user: null
};

export default function(state = initialState, action) {
    switch (action.type) {
        case SIGN_IN:
            if (action.payload.status !== SUCCESS)
                return state;

            return {
                ...state,
                user: action.payload.user
            };
        case SIGN_OUT:
                if (action.payload.status !== SUCCESS)
                    return state;

                return {
                    ...state,
                    user: null
                };
        default:
            return state;
    }
}

import React, { forwardRef, useImperativeHandle, useState } from "react";

import Button from "@material-ui/core/Button";
import Delete from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input"
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

// TODO: make index perhaps for utility
// TODO: error messages on line in future not generic
import ListRow from "../../objects/ListRow";
import FirebaseImage from "../../objects/FirebaseImage";
import { isEmpty } from "../../utility/String";
import { scaleImage } from "../../utility/Image";
import { readFile } from "../../utility/File";
import { LIST_VALIDATION_ENUM } from "../../Constants";

import "./ListEditor.css";

const useStyles = makeStyles(
    theme => (
        {
            addImagesButton: {
                marginLeft: "10px",
                position: "absolute"
            },
            addImagesInput: {
                opacity: 0,
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%"
            },
            comparisonInput: {
                marginLeft: "10px"
            },
            createListContainer: {
            },
            createList: {
                marginTop: "10px"
            },
            inline: {
                display: "inline"
            },
            rowNumber: {
                marginRight: "10px"
            },
            saveDialogError: {
                color: "red"
            },
            listInput: {
                marginBottom: "10px"
            }
        }
    )
);

// TODO: make images be allowed to be added later if text added first
const ListEditor = forwardRef(
    (props, ref) => {
        useImperativeHandle(ref,
            () => (
                {
                    clear() {
                        for (let row of rows)
                            if (row.src)
                                window.URL.revokeObjectURL(row.src);

                        setRows([]);
                        // make the default names a constant either in this file or in the constants file
                        // or a defaults files
                        setComparisonName("is higher priority");
                        setNewEntry("");
                        setLastRow(null);
                        setRowImagesCount(0);
                    },
                    validate() {
                        if (rows.length < 2) {
                            return LIST_VALIDATION_ENUM.FEWER_THAN_TWO_ROWS;
                        }

                        if (rowImagesCount > 0) {
                            return rowImagesCount === rows.length ? LIST_VALIDATION_ENUM.SUCCESS : LIST_VALIDATION_ENUM.IMAGES_COUNT;
                        }

                        for (let row of rows) {
                            if (isEmpty(row.text)) {
                                return LIST_VALIDATION_ENUM.EMPTY_ROWS;
                            }
                        }

                        return LIST_VALIDATION_ENUM.SUCCESS;
                    },
                    protoList() {
                        return [comparisonName, rows];
                    }
                }
            )
        );

        const classes = useStyles();

        const [comparisonName, setComparisonName] = useState("is higher priority");
        const [newEntry, setNewEntry] = useState("");
        const [lastRow, setLastRow] = useState(null);
        const [rows, setRows] = useState([]);
        const [rowImagesCount, setRowImagesCount] = useState(0);
        const [importCheck, setImportCheck] = useState(false);

        if (!importCheck && props.list) {
            // assumption rows is array in list, which is of type List
            const importedRows = props.list.rows.slice();
            let promises = [];

            if (importedRows.length == 0) {
                throw new Error("Rows cannot be empty");
            }

            const hasImages = importedRows[0].hasImage();
            const lastIndex = importedRows.length - 1;

            if (hasImages) {
                promises = importedRows.map(
                    (row) => row.image.generateLocalImage()
                );
            }

            Promise.all(promises)
                .then(
                    () => {
                        if (hasImages) {
                            setRowImagesCount(importedRows.length);
                            for (let i = 0; i < importedRows.length; ++i) {
                                importedRows[i].image.uploadedSrc = null;
                            }
                        }
                        setRows(importedRows);
                        setLastRow(
                            {
                                id: importedRows[lastIndex].id,
                                index: lastIndex
                            }
                        );
                        
                    }
                );

            setImportCheck(true);
        }

        const handleRowPaste = (event) => {
            // prevents "pasting" into field, instead lets this function handle it
            event.preventDefault();
            let pastedText;
            // IE
            if (window.clipboardDate && window.clipboardData.getData) {
                pastedText = window.clipboardData.getData("Text");
            } else if (event.clipboardData && event.clipboardData.getData) {
                pastedText = event.clipboardData.getData("text/plain");
            } else {
                return;
            }

            const lines = pastedText.split('\n');
            pasteLines(lines);
        }

        const pasteLines = (lines) => {
            if (lines.length === 0)
                return;


            let rowsCopy = rows.slice();
            for (let line of lines) {
                if (!validateNewEntry(line))
                    continue;

                rowsCopy.push(new ListRow(undefined, line, null));
            }

            if (rowsCopy.length === rows.length)
                return;

            setRows(rowsCopy);
        }

        const getRowIndex = (rowID) => {
            let index = -1;
            if (!rowID)
                return index;

            if (lastRow && lastRow.id === rowID) {
                // TODO: prevent empty state
                index = lastRow.index;
            } else {
                for (let i = 0; i < rows.length; ++i)
                    if (rows[i].id === rowID) {
                        index = i;
                        break;
                    }
            }

            return index;
        }

        const deleteRow = (id) => {
            const index = getRowIndex(id);

            if (index === -1)
                return;

            let rowsCopy = rows.slice();
            const deletedRows = rowsCopy.splice(index, 1);
            if (deletedRows && deletedRows.length && deletedRows[0].image) {
                deletedRows[0].image.revokeLocalSrc();
                setRowImagesCount(rowImagesCount - 1);
            }

            setLastRow(null);
            setRows(rowsCopy);
        }

        const addImagesToRows = (images) => {
            const promises = images.map(
                (image) => scaleImage("tmp", image)
            );

            return Promise.all(promises)
                .then(
                    (blobs) => {
                        const newEntries = blobs.map(
                            (blob) => {
                                return new ListRow(undefined, "", new FirebaseImage(null, null, blob));
                            }
                        )

                        // if failure of one, won't go in here
                        setRowImagesCount(rowImagesCount + images.length);
                        // readImageFile
                        setRows(
                            [
                                ...rows,
                                ...newEntries
                            ]
                        )
                    } 
                );
        }

        // validators
        const validateNewEntry = (entry) => {
            return !isEmpty(entry);
        }

        // handlers
        const handleAddImagesInputChange = (event) => {
            // gets list of files (array)
            const files = event.target.files;

            // TODO: set limit of number of files to upload + size
            // 200 MB
            const MAX_SIZE = 200000000;
            let promises = [];
            for (let file of files) {
                if (file.size >= MAX_SIZE) {
                    console.log("file size exceeded");
                    return;
                }

                promises.push(readFile(file));
            }

            Promise.all(promises)
                .then(
                    (images) => {
                        addImagesToRows(images);
                    }
                )
                .catch(
                    (error) => {
                        if (error) {
                            console.error("handleAddImageInputChange", error);
                            return;
                        }
                    }
                );
        }

        const handleComparisonNameChange = (event) => {
            setComparisonName(event.target.value);
        }

        const handleRowChange = (event) => {
            const rowID = event.target.getAttribute("data-rowid");
            const index = getRowIndex(rowID);

            if (index === -1)
                return;

            let rowsCopy = rows.slice();
            rowsCopy[index].text = event.target.value;

            setLastRow(
                {
                    id: rowID,
                    index: index
                }
            );
            setRows(rowsCopy);
        }

        const handleNewEntryRowChange = (event) => {
            setNewEntry(event.target.value);
        }

        const handleKeyUp = (event) => {
            const key = event.which || event.keyCode;
            // ARROW UP 
            if (key === 38) {
            // ARROW DOWN
            } else if (key === 40) {
            // ENTER KEY
            } else if (key === 13) {
                if (validateNewEntry(newEntry)) {
                    setRows(
                        [
                            ...rows,
                            new ListRow(undefined, newEntry, null)
                        ]
                    );
                }
                // scroll into view
                setNewEntry("");
            }
        }

        // render
        return (
            <div>
                { /* Create List Start */ }
                <div className={ `${ classes.createListContainer }` }>
                    <Typography className={ `title container` } variant="h4">
                        Start Prioritizing
                    </Typography>
                    { /* Create List Rows Start */ }
                    <Paper className={ `center ${ classes.createList } container` } elevation={ 6 }>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <Typography className={ `inline` } variant="body1">
                                                Which One
                                            </Typography>
                                            <Input className={ `${ classes.comparisonInput } inline` } onChange={ handleComparisonNameChange } type="text" value={ comparisonName } placeholder="is higher priority" />
                                            <Typography className={ `${ classes.inline } inline` } variant="body1">
                                                ?
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    { rows && rows.map(
                                            (row, index) => {
                                                return (
                                                    <TableRow className={ `handle` } key={ row.id }>
                                                        <TableCell>
                                                            <Typography className={ `inline ${ classes.rowNumber }` } variant="body1">
                                                                { index + 1 }
                                                            </Typography>
                                                            <TextField
                                                                className={ classes.listInput }
                                                                required
                                                                onChange={ handleRowChange }
                                                                value={ row.text }
                                                                inputProps={
                                                                    {
                                                                        "data-rowid": row.id
                                                                    }
                                                                } />
                                                            { row.image && row.image.src &&
                                                                <img src={ row.image.src } alt={ index } />
                                                            }
                                                            <IconButton className={ `${ classes.deleteRowButton } inline` } color="primary" variant="contained" onClick={ () => { deleteRow(row.id) } }>
                                                                <Delete color="primary" />
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            }
                                        )
                                    }
                                    <TableRow>
                                        <TableCell>
                                            <TextField autoFocus onChange={ handleNewEntryRowChange } onPaste={ handleRowPaste} onKeyUp={ handleKeyUp } value={ newEntry }>
                                            </TextField>
                                            <Button className={ `${ classes.addImagesButton } inline` } color="primary" variant="contained">
                                                Add Images
                                                <input className={ `${ classes.addImagesInput }` } type="file" accept="image/*" multiple onChange={ handleAddImagesInputChange }>
                                                </input>
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                    { /* Create List Rows End */ }
                </div>
                { /* Create List End */ }
            </div>
        );
    }
);

export default ListEditor;

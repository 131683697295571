import React from "react"

import { Switch, Route } from "react-router-dom"

import AggregatedResults from "../../screens/aggregated_results/AggregatedResults";
import Login from "../../screens/login/Login";
import Register from "../../screens/register/Register";
import Results from "../../screens/results/Results";
import RunList from "../../screens/run_list/RunList";

import NotFound from "../../screens/not_found/NotFound";

const DefaultRouter = () => (
    <Switch>
        <Route exact path="/" component={ Login } />
        <Route exact path="/register/:username?" component={ Register } />
        <Route exact path="/results/:uid/:lid/:ruid" component={ Results } />
        <Route path = "/run-list/:uid/:lid" component={ RunList } />
        <Route exact path="/aggregated-results/:uid/:lid" component={ AggregatedResults } />
        <Route component={ NotFound } />
    </Switch>
);

export default DefaultRouter;

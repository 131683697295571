import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import { Redirect } from "react-router";
import { Link } from "react-router-dom";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";

import Typography from "@material-ui/core/Typography";

import { getList, updateListResponse } from "../../backend/Database";
import { randomID } from "../../utility/Random";
import Sorter from "../../objects/Sorter";

import UserError from "../../components/user_error/UserError";

import "./RunList.css";

// TODO: use lists = [list] for useEffect
function RunList(props) {
    const user = useSelector(state => state.auth.user);

    // variable to force update of state
    const [cookies, setCookie] = useCookies(["userName", "userID"]);
    const [update, setUpdate] = useState(false);
    const [list, setList] = useState({});
    const [sorter, setSorter] = useState({});
    const [tempUserName, setTempUserName] = useState("");
    const [userError, setUserError] = useState({});
    const [isResponseUploaded, setIsResponseUploaded] = useState(false);

    const handleLeftClick = () => {
        sorter.setResult(-1);
        updateUI();
    };

    const handleRightClick = () => {
        sorter.setResult(1);
        updateUI();
    };

    const updateUI = () => {
        setUpdate(!update);

        if (sorter.isSorted) {
            uploadResponse();
        }
    }

    const uploadResponse = () => {
        const userID = props.match.params.uid;
        const listID = props.match.params.lid;
        let uid;
        let displayName;

        if (user) {
            uid = user.uid;
            displayName = user.displayName;
        }

        if (!uid || !displayName) {
            if (!cookies.userID || !cookies.userName) {
                // TODO: display error
                setUserError(
                    {
                        title: "Cannot run list",
                        message: "Not logged in and no temporary user name"
                    }
                );
                return;
            }

            uid = cookies.userID;
            displayName = cookies.userName;
        }

        if (!userID || !listID) {
            setUserError(
                {
                    title: "No User or List",
                    message: "Either user is not logged in (or no temporary user) or list is invalid"
                }
            );
            return;
        }

        const response = sorter.response(uid, displayName);

        updateListResponse(listID, userID, response)
            .then(
                () => {
                    setIsResponseUploaded(true);
                }
            )
            .catch(
                (error) => {
                    setIsResponseUploaded(true);
                    // TODO: set error & show
                    console.error("error has occured", error);
                }
            );
    }

    const handleUndo = () => {
        sorter.undo();

        setUpdate(!update);
    };

    const handleTempUserNameChange = (event) => {
        setTempUserName(event.target.value);
    };

    const handleNameSubmit = () => {
        setCookie("userName",
            tempUserName,
            {
                path: '/'
            }
        );
        
        setCookie("userID",
            randomID(),
            {
                path: '/'
            }
        );
    };

    useEffect(
        () => {
            // user id
            const userID = props.match.params.uid;
            const listID = props.match.params.lid;
            if (!userID || !listID) {
                setUserError(
                    {
                        title: "URL is invalid",
                        message: "Missing either, user id, or list id"
                    }
                );
                return;
            }

            getList(userID, listID)
                .then(
                    (list) => {
                        // TODO: If deleted then show error
                        if (!list || list.isDeleted) {
                            setUserError(
                                {
                                    title: "List is invalid",
                                    message: "It doesn't exist, is deleted, or is corrupted"
                                }
                            );
                            return;
                        }

                        const sorter = new Sorter(list.rows.slice());

                        setList(list);
                        setSorter(sorter);
                    }
                )
                .catch(
                    (error) => {
                        // TODO: exit here
                        console.error(error);
                    }
                );
        }, []
    );

    if (userError && userError.title && userError.message) {
        return (
            <div>
                <UserError
                    title={ userError.title }
                    message={ userError.message } />
            </div>
        );
    }

    if (!user && (!cookies.userName || !cookies.userID)) {
        return (
            <div>
                <Typography variant="h5" className={ `title container` }>
                    What is your name
                </Typography>
                <Paper className={ `container center` } spacing={ 4 } elevation={ 6 }>
                    <Grid className={ `name-container` } container>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="name"
                            type="name"
                            fullWidth
                            value={ tempUserName }
                            onChange={ handleTempUserNameChange } />
                        <Grid className={ "button-container" }>
                            <Button onClick={ handleNameSubmit } color="primary">
                                Submit
                            </Button>
                            <Button component={ Link } to="/" color="primary">
                                Login Page
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </div>
        );
    }

    // TODO: below I have uploaded src, use that to display image as is being done in the list editor, except it is uploaded src
    // should be much easier than before
    return (
        <div className={ `center` }>
            { isResponseUploaded && (<Redirect to={ `/results/${ props.match.params.uid }/${ props.match.params.lid }/${ user && user.uid || cookies.userID }` } />) }
            <Typography variant="h5" className={ `title container` }>
                Which one is {list.comparison}?
            </Typography>

            <Paper className={ `container center` } spacing={ 4 } elevation={ 6 }>
                <Grid className={ `` } container spacing={ 4 } alignItems="flex-start">
                    <Grid item className={ `comparison-container` }>
                        <Button onClick={ handleLeftClick }>
                            { !sorter.isSorted && sorter.left && (<span><p>{sorter.left().text}</p> <img src={sorter.left().image.uploadedSrc} /> </span>) }
                        </Button>

                        <p className="divider">Vs.</p>

                        <Button onClick={ handleRightClick }>
                            { !sorter.isSorted && sorter.right && (<span><p>{sorter.right().text}</p> <img src={sorter.right().image.uploadedSrc} /> </span>) }
                        </Button>
                    </Grid>
                </Grid>
            </Paper>

            <Grid className={ `container center undo-container` }>
                <Button variant="text" color="secondary" onClick={ handleUndo }>
                    Undo
                </Button>
            </Grid>
        </div>
    );
    
}

export default RunList;

import React, { useState } from "react";
import { useSelector } from "react-redux";

import AccountCircle from "@material-ui/icons/AccountCircle";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Paper from "@material-ui/core/Paper";
import Snackbar from "@material-ui/core/Snackbar";
import Typography from "@material-ui/core/Typography";

import Alert from "../../components/alert/Alert";
import { signOut } from "../../backend/Authentication";
import { updateUserName } from "../../backend/Database";

import "./Profile.css";

function Profile(props) {
    const user = useSelector(state => state.auth.user);

    const [alertSeverity, setAlertSeverity] = useState("");
    const [name, setName] = useState("");
    const [message, setMessage]   = useState("");
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    
    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const handleSignOut = () => {
        signOut().then(
            () => {
                props.history.push("/");
            }
        )
    }

    const handleSave = () => {
        if (!validate()) {
            errorSnackbar("name needs to be contain at least 4 characters");
            return;
        }

        // update user name in database & in the profile of the firebase auth user
        // TODO: merge both functions into one in database or authentication
        // or leave seperate as they are different, db vs auth
        Promise.all([updateUserName(user.uid, name), user.updateProfile(
            {
                displayName: name
            }
        )]).then(
            () => {
                successSnackbar("updated name");
            }
        ).catch(
            (error) => {
                errorSnackbar("failed to update name");
            }
        );
    }

    const validate = () => {
        return name.length > 3;
    }

    const errorSnackbar = (message) => {
        setMessage(message);
        setAlertSeverity("error");
        setSnackbarOpen(true);
    }

    const successSnackbar = (message) => {
        setMessage(message);
        setAlertSeverity("success");
        setSnackbarOpen(true);
    }

    const handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway")
            return;

        setSnackbarOpen(false);
    }

    const handleAlertClose = (event, reason) => {
        if (reason === "clickaway")
            return;

        setSnackbarOpen(false);
    }

    return (
        <div className={ `center` }>
            <Typography variant="h4" className={ `title container` }>
                Profile
            </Typography>

            <Paper className={ `container center` } spacing={ 4 } elevation={ 6 }>
                <Grid className={ `` } container spacing={ 4 } alignItems="flex-start">
                    <Grid item>
                        <Grid container alignItems="flex-end" className={ `form` }>
                            <Grid item>
                                <AccountCircle />
                            </Grid>
                            <Grid item>
                                <FormControl alignItems="flex-end">
                                    <InputLabel htmlFor="name">Name</InputLabel>
                                    <Input id="name" type="text" value={ name } onChange={ handleNameChange } />
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid className={ `buttons` } container alignItems="flex-end">
                            <Grid item>
                                <Button variant="filled" onClick={ handleSave }>
                                    Save
                                </Button>
                                <Button onClick={ handleSignOut }>
                                    Sign Out
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
            
            <Snackbar open={ snackbarOpen } autoHideDuration={ 2500 } onClose={ handleSnackbarClose} >
                <Alert onClose={ handleAlertClose } severity={ alertSeverity }>
                    { message }
                </Alert>
            </Snackbar>
        
        </div>
    )

}

export default Profile;

import { SUCCESS, GENERIC_ACTION, GENERIC_FAILURE_ACTION, SIGN_IN, SIGN_OUT } from "../../Constants";

/*
 * Authentication actions
 */

/*
 * Sign In
 */
export const signInFailureAction = (error) => {
    return GENERIC_FAILURE_ACTION(SIGN_IN, error);
}

export const signInSuccessAction = (user) => {
    return GENERIC_ACTION(SIGN_IN,
        SUCCESS,
        {
            user: user
        }
    );
}

/*
 * Sign out
 */
export const signOutFailureAction = (error) => {
    return GENERIC_FAILURE_ACTION(SIGN_OUT, error);
}

export const signOutSuccessAction = () => {
    return GENERIC_ACTION(SIGN_OUT, SUCCESS);
}

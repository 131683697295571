import React from "react";

import { Link } from "react-router-dom";

import MoodBadIcon from '@material-ui/icons/MoodBad';
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import "./UserError.css";

// TODO: fix link, they are absolute, but to is relative
// TODO: decide if edit link requires uid, or for consistency
function UserError(props) {
    
    const { title, message } = props;

    return (
        <div className={ `center` }>
            <Typography color="primary" variant="h1" className={ `title container` }>
                <MoodBadIcon fontSize = "large" className="title-sad"/>
            </Typography>

            <Typography color="primary" variant="h4" className={ `title-error container` }>
                An error has occured.
            </Typography>

            <Grid container spacing={ 4 } alignItems="flex-end" className="list-panel">
                <Grid item>
                    <Button component={ Link } to={"/"} variant="text" color="secondary">
                        Home Page
                    </Button>
                </Grid>
            </Grid>
        </div>
    );

};

export default UserError;

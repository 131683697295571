export const blobFromURL = async (url) => {
    const res = await fetch(url);

    return await res.blob();
}

// a Blob is almost a File, it's just missing the two properties which
// we will add
export const blobToFile = (blob, filename) => {
    blob.lastModifiedDate = new Date();
    blob.name = filename;

    return blob;
}

export const readFile = (file) => {
    return new Promise(
        (resolve, reject) => {
            let reader = new FileReader();

            reader.onload = (event) => {
                resolve(event.target.result);
            };

            reader.onerror = (error) => {
                reader.abort();
                reject(error);
            };

            reader.readAsDataURL(file);
        }
    );
}

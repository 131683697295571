import React, { useState } from "react";

import AccountCircle from "@material-ui/icons/AccountCircle";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Paper from "@material-ui/core/Paper";
import PasswordIcon from "@material-ui/icons/VpnKey";
import Typography from "@material-ui/core/Typography";

import Snackbar from "@material-ui/core/Snackbar";
import Alert from "../../components/alert/Alert";

import { EMAIL_REGEX } from "../../Constants.js";
import { containsSpace } from "../../utility/String.js";
import { signUp } from "../../backend/Authentication";

import "./Register.css";

// TODO: enter submit form
// TODO: see password on press icon
// TODO: email validation
function Register(props) {

    const [username, setUsername]               = useState(props.match.params.username || "");
    const [name, setName]                       = useState("");
    const [password, setPassword]               = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const [message, setMessage]                 = useState("");
    const [errorMessage, setErrorMessage]       = useState("");
    const [alertSeverity, setAlertSeverity]     = useState("");
    const [snackbarOpen, setSnackbarOpen]       = useState(false);

    const errorSnackbar = (message) => {
        setMessage(message);
        setAlertSeverity("error");
        setSnackbarOpen(true);
    }

    const successSnackbar = (message) => {
        setMessage(message);
        setAlertSeverity("success");
        setSnackbarOpen(true);
    }
    const handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway")
            return;

        setSnackbarOpen(false);
    }

    const handleAlertClose = (event, reason) => {
        if (reason === "clickaway")
            return;

        setSnackbarOpen(false);
    }

    const handleUsernameChange = (event) => {
        setUsername(event.target.value);
    }

    const handleNameChange = (event) => {
        setName(event.target.value);
    }

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    }

    const handleConfirmPasswordChange = (event) => {
        setConfirmPassword(event.target.value);
    }

    const handleRegister = () => {
        if (!validate()) {
            errorSnackbar("there are errors in the form, password must be > 7 characters & not contain spaces");
            return;
        }

        // TODO: redirect after signUp to landing page
        // TODO: handle name passed into url, params
        signUp(username, password, name);
    }

    // TODO; seperate parts of validation, so in end validateUsername() && validatePassword() && ...
    const validate = () => {
        return EMAIL_REGEX.test(username) && name.length > 2 && password.length > 7 && !containsSpace(password) && password == confirmPassword;
    }

    return (
        <div className={ `center container` }>
            <Typography className={ `center title` } variant="h4">
                Create Account
            </Typography>
            <Paper className={ `center register-paper-container` } elevation={ 6 }>

                <Grid container className={ `input-container` } alignItems="flex-end">
                    <Grid item>
                        <AccountCircle />
                    </Grid>
                    <Grid item>
                        <FormControl alignItems="flex-end">
                            <InputLabel htmlFor="username">Email</InputLabel>
                            <Input id="username" type="email" value={ username } onChange={ handleUsernameChange } />
                        </FormControl>
                    </Grid>
                </Grid>

                <Grid container className={ `input-container` } alignItems="flex-end">
                    <Grid item>
                        <AccountCircle />
                    </Grid>
                    <Grid item>
                        <FormControl alignItems="flex-end">
                            <InputLabel htmlFor="name">Name</InputLabel>
                            <Input id="name" type="text" value={ name } onChange={ handleNameChange } />
                        </FormControl>
                    </Grid>
                </Grid>

                <Grid container className={ `input-container` } alignItems="flex-end">
                    <Grid item>
                        <PasswordIcon />
                    </Grid>
                    <Grid item>
                        <FormControl>
                            <InputLabel htmlFor="password">Password</InputLabel>
                            <Input id="password" type="password" value={ password } onChange={ handlePasswordChange } />
                        </FormControl>
                    </Grid>
                </Grid>

                <Grid container className={ `input-container` } alignItems="flex-end">
                    <Grid item>
                        <PasswordIcon />
                    </Grid>
                    <Grid item>
                        <FormControl>
                            <InputLabel htmlFor="password">Confirm Password</InputLabel>
                            <Input id="confirm-password" type="password" value={ confirmPassword } onChange={ handleConfirmPasswordChange }/>
                        </FormControl>
                    </Grid>
                </Grid>

                <Grid className={ `register-button-container` } container  alignItems="flex-end">
                    <Grid item>
                        <Button variant="contained" color="primary" onClick={ handleRegister }>
                            Register
                        </Button>
                    </Grid>
                </Grid>

            </Paper>
            
            <Snackbar open={ snackbarOpen } autoHideDuration={ 2500 } onClose={ handleSnackbarClose} >
                <Alert onClose={ handleAlertClose } severity={ alertSeverity }>
                    { message }
                </Alert>
            </Snackbar>
        </div>
    );

}

export default Register;

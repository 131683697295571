import FirebaseImage from "./FirebaseImage"; 

import { randomID } from "../utility/Random";

// note: image is firebase image
export default function ListRow(id=randomID(), text="", image) {
    this.id = id;
    this.text = text;
    this.image = image;

    this.hasImage = () => {
        return this.image && this.image.hasImage();
    };

    this.uploadImageToFirebase = (prefix, index) => {
        return this.image.uploadToFirebase(prefix, index);
    };

    this.toFirebaseObject = () => {
        return {
            text: this.text,
            imagePath: this.image && this.image.uploadedSrc || ""
        };
    };
}

export const snapshotDataToListRow = (data) => {
    return new ListRow(randomID(), data.text, new FirebaseImage(null, data.imagePath, null));
}

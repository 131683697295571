import React, { useState, useEffect } from "react";

import MoodBadIcon from '@material-ui/icons/MoodBad';
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import { Link } from "react-router-dom";

import "./NotFound.css";

function NotFound() {

    const [delayed, setDelayed] = useState(true);

    useEffect(
        () => {
            const timeout = setTimeout(
                () => setDelayed(false), 300
            );

            return () => clearTimeout(timeout);
        },
        []
    );

    if (delayed) {
        return (
            <div>
            </div>
        );
    } else {
        return (
            <div className={ `center` }>
                <Typography color="primary" variant="h4" className={ `title-404 title container` }>
                    4<MoodBadIcon fontSize = "large" />4 Error...
                    <br />
                    Page Not Found.
                </Typography>

                <Grid container spacing={ 4 } alignItems="flex-end" className="list-panel">
                    <Grid item>
                        <Button component={ Link } to={"/"} variant="text" color="secondary">
                            Home Page
                        </Button>
                    </Grid>
                </Grid>
            </div>
        );
    }

}

export default NotFound;
import ListResponse from "./ListResponse";

// TODO: make comparisons own object in this class
// make them an inner object

// TODO: add can undo function
export default function Sorter(inputArray) {
    if (!Array.isArray(inputArray)) {
        throw new TypeError("Can only sort arrays");
    }

    this.isSorted = false;

    const originalArray = inputArray.slice();
    const comparisons = [];
    const indexMap = createIndexMap(inputArray);
    
    let array = inputArray.slice();
    let left;
    let right;
    let mid;
    let high = inputArray.length;
    let start = 1;
    let _this = this;
    
    this.left = function() {
        return leftItem();
    }

    this.right = function() {
        return rightItem();
    }

    function leftItem() {
        return array[start];
    }

    function rightItem() {
        return array[mid];
    }

    function orderedIndexList() {
        return array.map(
            (item) => indexMap[item.id]
        );
    };

    this.response = function(userID, userName) {
        return new ListResponse(Date.now(), orderedIndexList(), comparisons, userID, userName);
    }

    this.undo = function() {
        // need at least 2 comparisons to undo
        // previous & current comparison (think of them as states)
        if (comparisons.length < 2) {
            return;
        }

        const canceledComparison = undoCompare();

        if (!canceledComparison) {
            return;
        }

        canceledComparison.unixUndoTime = Date.now();
        setupCompare(canceledComparison);
    }

    this.setResult = function(result) {
        if (this.isSorted) {
            return
        }

        if (!Number.isInteger(result)) {
            throw new TypeError("Result must be an integer");
        }

        if (result < 0) {
            right = mid;
        } else {
            left = mid + 1;
        }

        const endIndex = comparisons.length - 1;
        const endElement = comparisons[endIndex];

        const id = (result < 0 ? leftItem() : rightItem()).id;

        endElement.result = result;
        endElement.selectedItem = {
            id: id,
            index: indexMap[id]
        };
        endElement.unixClickTime = Date.now();
        endElement.timeShownMS = endElement.unixClickTime - endElement.unixShowTime;

        if (left >= right) {
            sortArray();
            startLoop();
        }

        setupCompare();
    };

    startLoop();
    setupCompare();

    function createIndexMap(array) {
        let obj = {};
        for (let i = 0; i < array.length; ++i) {
            obj[array[i].id] = i;
        }

        return obj;
    }

    function startLoop() {
        if (start >= high) {
            _this.isSorted = true;
            return;
        }

        left = 0;
        right = start;
    };

    function addNewComparison(canceledComparison) {
        let comparison = {
            array: array.map(
                (item) => item.id
            ),
            left: left,
            right: right,
            mid: mid,
            start: start,
            unixShowTime: Date.now()
        };

        if (canceledComparison && canceledComparison.unixShowTime) {
            // delete comparison.canceled
            comparison.canceled = [canceledComparison];

            if (canceledComparison.canceled) {
                comparison.canceled = comparison.canceled.concat(canceledComparison.canceled);
                delete canceledComparison.canceled;
            }
        }

        comparisons.push(comparison)
    }

    function restore(comparison) {
        array = comparison.array.map(
            (id) => originalArray[indexMap[id]]
        );
        left = comparison.left;
        right = comparison.right;
        mid = comparison.mid;
        start = comparison.start;
    }

    function undoCompare() {
        // remove current comparison
        comparisons.pop();

        // remove & get last comparion
        // take advantage of pop to use it
        const lastComparison = comparisons.pop();

        // pass comparison into restore to restore
        // state of sorter during comparison
        restore(lastComparison);

        return lastComparison;
    }

    function setupCompare(canceledComparison) {
        mid = (left + right) >>> 1;

        const pivot = leftItem();
        const midObject = rightItem();

        if (pivot) {
            addNewComparison(canceledComparison);
        }

        if (!pivot) {
            _this.setResult(-1);
        } else if (!midObject) {
            _this.setResult(1);
        }
    };

    function sortArray() {
        const pivot = array[start];
        let n = start - left;
        while (n > 0) {
            array[left + n] = array[left + --n];
        }
        
        array[left] = pivot;
        ++start;
    }

}
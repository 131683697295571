import React, { useState } from "react";

import AccountCircle from "@material-ui/icons/AccountCircle";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Paper from "@material-ui/core/Paper";
import PasswordIcon from "@material-ui/icons/VpnKey";
import Snackbar from "@material-ui/core/Snackbar";

import { Link } from "react-router-dom";

import { GoogleLoginButton, FacebookLoginButton } from "react-social-login-buttons";

import Alert from "../../components/alert/Alert";

import { containsSpace } from "../../utility/String.js";
import { resetPassword, signIn, socialSignIn } from "../../backend/Authentication";
import { EMAIL_REGEX, FACEBOOK_AUTH_PROVIDER, GOOGLE_AUTH_PROVIDER } from "../../Constants";

import "./Login.css";

// TODO: error & success functions for snackbar displays
// TODO: finally?? usage for promises so always show perhaps??
function Login(props) {

    const [alertSeverity, setAlertSeverity] = useState("");
    const [message, setMessage]             = useState("");
    const [password, setPassword]           = useState("");
    const [snackbarOpen, setSnackbarOpen]   = useState(false);
    const [username, setUsername]           = useState("");

    const handleGoogle = () => {
        socialSignIn(GOOGLE_AUTH_PROVIDER)
            .then(
                (res) => {
                    successSnackbar("Successfully signed in");
                }
            )
            .catch(
                (error) => {
                    errorSnackbar("Unable to sign in");
                }
            );
    }

    const handleFacebook = () => {
        socialSignIn(FACEBOOK_AUTH_PROVIDER)
            .then(
                (res) => {
                    successSnackbar("Successfully signed in");
                }
            )
            .catch(
                (error) => {
                    errorSnackbar("Unable to sign in");
                }
            );
    }

    const handleUsernameChange = (event) => {
        setUsername(event.target.value);
    }

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    }

    const errorSnackbar = (message) => {
        setMessage(message);
        setAlertSeverity("error");
        setSnackbarOpen(true);
    }

    const successSnackbar = (message) => {
        setMessage(message);
        setAlertSeverity("success");
        setSnackbarOpen(true);
    }

    const handleResetPassword = () => {
        resetPassword(username)
            .then(
                () => {
                    successSnackbar("Sent reset email, check your email");
                }
            ).catch(
                (error) => {
                    errorSnackbar("Unable to send reset email, please check the email you typed in");
                }
            )
    }

    const handleSignIn = () => {
        if (!validate()) {
            errorSnackbar("Email or password isn't formatted properly");
            return;
        }
        signIn(username, password)
            .then(
                (user) => {
                    successSnackbar("Successfully signed in");
                }
            ).catch(
                (error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    let message = "Unable to sign in";
                    if (errorCode === "auth/wrong-password" || errorCode === "auth/user-not-found") {
                        message = "Incorrect username or password";
                    }
                    errorSnackbar(message);
                }
            )
    }

    const handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway")
            return;

        setSnackbarOpen(false);
    }

    const handleAlertClose = (event, reason) => {
        if (reason === "clickaway")
            return;

        setSnackbarOpen(false);
    }

    const validate = () => {
        return EMAIL_REGEX.test(username) && password.length > 7 && !containsSpace(password);
    }

    return (
        <div className={ `login-container` }>
            <Paper className={ `login-paper-container` } elevation={ 6 }>
                <h1 className={ `sign-in-header` }>Sign In</h1>

                <Grid className={ `login-content-container` } container alignItems="flex-start">
                    <Grid item className={ `left-side` }>
                        <Grid container className={ `form` } alignItems="flex-end">
                            <Grid item>
                                <AccountCircle />
                            </Grid>
                            <Grid item>
                                <FormControl alignItems="flex-end">
                                    <InputLabel htmlFor="username">Email</InputLabel>
                                    <Input id="username" type="email" value={ username } onChange={ handleUsernameChange } />
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid container className={ `form` } alignItems="flex-end">
                            <Grid item>
                                <PasswordIcon />
                            </Grid>
                            <Grid item>
                                <FormControl>
                                    <InputLabel htmlFor="password">Password</InputLabel>
                                    <Input id="password" type="password" value={ password } onChange={ handlePasswordChange } />
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid className={ `manual-input-container` } container>
                            <Grid item>
                                <Button variant="text" color="primary" onClick={ handleSignIn }>
                                    Sign In
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button className={ `register-button` } component={ Link } to={ `/register/${ username || "" }` } variant="contained" color="primary">
                                    Register
                                </Button>
                            </Grid>
                            <Grid item className={ `reset-password-container` }>
                                <Button variant="text" color="secondary" onClick={ handleResetPassword }>
                                    Reset Password
                                </Button>
                            </Grid>
                        </Grid>

                        {/* <Grid className={ `reset-password-container` } container>
                        </Grid> */}
                    </Grid>

                    <Grid item className={ `social-login-border-right` }>
                        <Grid item>
                            <GoogleLoginButton onClick={ handleGoogle }/>
                            <FacebookLoginButton onClick={ handleFacebook } />
                        </Grid>
                    </Grid>

                </Grid>
            </Paper>

            <Snackbar open={ snackbarOpen } autoHideDuration={ 2500 } onClose={ handleSnackbarClose} >
                <Alert onClose={ handleAlertClose } severity={ alertSeverity }>
                    { message }
                </Alert>
            </Snackbar>
        </div>
    );

}

export default Login;

import React, { useEffect, useRef, useState } from "react";

import { useSelector } from "react-redux";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Share from "@material-ui/icons/Share";
import Slide from "@material-ui/core/Slide";
import Snackbar from "@material-ui/core/Snackbar";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";

import { Link } from "react-router-dom";
import { Redirect } from "react-router";
import CopyText from "react-copy-text";

import { getList, saveEditList, saveNewList } from "../../backend/Database";
import Alert from "../../components/alert/Alert";
import ListEditor from "../../components/list_editor/ListEditor";
import { LIST_VALIDATION_ENUM } from "../../Constants";
import { generateWebLink } from "../../utility/Link";
import { isEmpty } from "../../utility/String";

// PERHAPS: use react ref to call list editor validator & export functions, or any more that we may need
// essentially find a way to call functions from a component, perhaps share object through prop??

import "./CreateList.css";

const Transition = React.forwardRef(
    function Transition(props, ref) {
        return <Slide direction="up" ref={ ref } { ...props } />
    }
);

// TODO: change to css along with changing class names to css style with -'s
// TODO: need to worry about duplicate images??, probably not??, or dupe rows since caption can change?
// TODO: maybe handle paste images??, probably not though? nice to have??
const useStyles = makeStyles(
    theme => (
        {
            addImagesButton: {
                marginLeft: "10px",
                position: "fixed"
            },
            addImagesInput: {
                opacity: 0,
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%"
            },
            comparisonInput: {
                marginLeft: "10px"
            },
            createListContainer: {
            },
            createList: {
                marginTop: "10px",
                maxWidth: "700px"
            },
            inline: {
                display: "inline"
            },
            rowNumber: {
                marginRight: "10px"
            },
            title: {
                margin: "0 auto",
                maxWidth: "700px"
            }
        }
    )
);

// TODO: simple test to see if edited list is same as before,
// then do not update db
function CreateList(props) {
    const classes = useStyles();
    const user = useSelector(state => state.auth.user);
    
    let listEditorRef = useRef(null);

    const [alertSeverity, setAlertSeverity] = useState("");
    const [isSaveDialogOpen, setIsSaveDialogOpen] = useState(false);
    const [list, setList] = useState("");
    const [listName, setListName] = useState("");
    const [message, setMessage]   = useState("");
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const [redirectURL, setRedirectURL] = useState("");
    const [shareListText, setShareListText] = useState("");

    useEffect(
        () => {
            // user id
            // if userid in url does not match id of current user, display error
            // this.props.getList(this.props.user.uid, this.props.match.params.id)
            const listID = props.match.params.lid;
            if (!listID) {
                return;
            }

            getList(user.uid, listID)
                .then(
                    (list) => {
                        // TODO: if is temporary, then only allow run once, so if already response then show error
                        if (!list || list.isDeleted || list.hasResponses()) {
                            // TODO: show error, & redirect
                            return;
                        }

                        if (list.ownerID !== user.uid) {
                            // show error, don't have permission etc
                            return;
                        }

                        setList(list);
                    }
                )
                .catch(
                    (error) => {
                        console.error(error);
                    }
                );
        }, []
    );

    const errorSnackbar = (message) => {
        setMessage(message);
        setAlertSeverity("error");
        setSnackbarOpen(true);
    }

    const successSnackbar = (message) => {
        setMessage(message);
        setAlertSeverity("success");
        setSnackbarOpen(true);
    }

    const handleSaveDialogClose = () => {
        setListName("");
        setIsSaveDialogOpen(false);
    }

    // TODO: add spinner
    const handleSaveDialogSave = () => {
        if (!validateListName()) {
            errorSnackbar("Please enter a name for the list");
            return;
        }

        const [comparisonName, rows] = listEditorRef.protoList();

        setIsSaveDialogOpen(false);
        // TODO: generate List object from list editor perhaps??
        saveNewList(user.uid, comparisonName, listName, rows, false)
            .then(
                (key) => {
                    successSnackbar(`Saved ${ listName } successfully`);
                    setIsSaved(true);
                }
            )
            .catch(
                (error) => {
                    errorSnackbar("failed to save list");
                }
            );
    };

    const handleSave = () => {
        const [code, message] = listEditorRef.validate();
        if (code !== LIST_VALIDATION_ENUM.SUCCESS[0]) {
            errorSnackbar(message);
            return;
        }

        // TODO: remove success validation message
        successSnackbar(message);

        if (!list) {
            setIsSaveDialogOpen(true);
            return;
        }

        const [comparisonName, rows] = listEditorRef.protoList();

        list.comparison = comparisonName;
        list.rows = rows;

        saveEditList(list)
            .then(
                () => {
                    successSnackbar(`Updated ${ listName } successfully`);
                    setIsSaved(true);
                }
            )
            .catch(
                (error) => {
                    console.log("error", error);
                    errorSnackbar("Failed to update list");
                }
            );
        
    }

    const handleListNameChange = (event) => {
        // TODO: validate list name perhaps on save
        setListName(event.target.value);
    }

    const handleClear = () => {
        listEditorRef.clear();
    }

    const handleStart = () => {
        const [code, message] = listEditorRef.validate();
        if (code !== LIST_VALIDATION_ENUM.SUCCESS[0]) {
            errorSnackbar(message);
            return;
        }

        const [comparisonName, rows] = listEditorRef.protoList();
        
        saveNewList(user.uid, comparisonName, "", rows, true).then(
            (key) => {
                const redirectLink = generateWebLink(
                    {
                        ownerID: user.uid,
                        key: key
                    },
                    false
                );
                setRedirectURL(redirectLink);
                successSnackbar(`Saved ${ listName } successfully`);
            }
        ).catch(
            (error) => {
                errorSnackbar("unable to save list, please try again");
            }
        );
        // TODO: redirect, & if temporary on results page add save option with share option
    };

    const handleShare = () => {
        if (!validateListName()) {
            errorSnackbar("Please enter a name for the list");
            return;
        }

        const [comparisonName, rows] = listEditorRef.protoList();

        setIsSaveDialogOpen(false);
        saveNewList(user.uid, comparisonName, listName, rows, false)
            .then(
                (key) => {
                    const link = generateWebLink(
                        {
                            ownerID: user.uid,
                            key: key
                        },
                        true);
                    setShareListText(link);
                    successSnackbar(`Saved ${ listName } and Copied link to clipboard`);
                    setIsSaved(true);
                }
            )
            .catch(
                (error) => {
                    errorSnackbar("failed to save list");
                }
            );
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway")
            return;

        setSnackbarOpen(false);
    };

    const handleAlertClose = (event, reason) => {
        if (reason === "clickaway")
            return;

        setSnackbarOpen(false);
    };

    // TODO: decide if pass in name for being universal or use state instead
    // TODO: decide if need validation class, or have general validation functions
    // where used multiple times?
    // PERHAPS: allow prefix of spaces, doesn't seem bad, only if ALL spaces, don't allow
    const validateListName = () => {
        return !isEmpty(listName) && listName.length > 0;
    }

    if (isSaved) {
        return (
            <Redirect to="/my-lists" />
        )
    }

    if (redirectURL && redirectURL !== "") {
        return (
            <div>
                <Redirect to={ redirectURL } />
            </div>
        );
    }

    return (
        <div>
            { /* Save Dialog Start */ }
            <Dialog
                open={ isSaveDialogOpen }
                keepMounted
                TransitionComponent={ Transition }
                onClose={ handleSaveDialogClose }>
                <DialogTitle>
                    Choose a name for your list
                </DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        fullWidth
                        id="name"
                        label="Form Name"
                        margin="dense"
                        type="text"
                        onChange={ handleListNameChange }
                        value={ listName } />
                </DialogContent>
                <DialogActions>
                    <Button onClick={ handleSaveDialogClose } color="primary">
                        Cancel
                    </Button>
                    <Button onClick={ handleSaveDialogSave } color="secondary">
                        Save
                    </Button>
                    <IconButton tooltip="Hide" onClick={ handleShare }>
                        <Share color="primary" />
                    </IconButton>
                    <CopyText text={ shareListText }/>
                </DialogActions>
            </Dialog>

            { /* Save Dialog End */ }
            { /* Create List Start */ }
            <div className={ `${ classes.createListContainer }` }>
                <ListEditor list={ list } ref={ ins => listEditorRef = ins }/>
                { /* Create List Rows End */ }
                <Grid container spacing={ 4 } alignItems="flex-end" className="list-panel">
                    <Grid item>
                        <Button component={ Link } to="/my-lists" variant="text" color="primary">
                            My Lists
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant="text" color="secondary" onClick={ handleClear }>
                            Clear
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant="text" color="primary" onClick={ handleSave }>
                            Save
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" color="primary" onClick={ handleStart }>
                            Start Prioritizing
                        </Button>
                    </Grid>
                </Grid>
            </div>
            <Snackbar open={ snackbarOpen } autoHideDuration={ 2500 } onClose={ handleSnackbarClose} >
                <Alert onClose={ handleAlertClose } severity={ alertSeverity }>
                    { message }
                </Alert>
            </Snackbar>

            { /* Create List End */ }
        </div>
    );
};

export default CreateList;

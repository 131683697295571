import React from "react";
import Fab from '@material-ui/core/Fab';
import MailIcon from '@material-ui/icons/Mail';

function FeedbackBtn() {

    return (
        <a href="mailto:support@prioritizer.ca" target="_blank" className="feedback-btn" style={
            {
                textDecoration: "none"
            }
        }>
            <Fab variant="extended" color="primary" aria-label="add">
                <MailIcon/>
                Send Feedback
            </Fab>
        </a>
    );

}

export default FeedbackBtn;

import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import Ranker from "../../objects/Ranker";
import { generateWebLink } from "../../utility/Link";
import { getList } from "../../backend/Database";

import UserError from "../../components/user_error/UserError";

import "./AggregatedResults.css";

function AggregatedResults(props) {

    const user = useSelector(state => state.auth.user);
    // PERHAPS: switch to useState({}),
    // if want to change output in ranker, perhaps instead of
    // 2D array, can use { header: [], body: [] }
    // each array containing items to display (can be a component)
    // or use the first idea for columns & rows
    const [output, setOutput] = useState([]);
    const [showResponses, setShowResponses] = useState(true);
    const [showResponseText, setShowResponseText] = useState("Hide Results");
    const [list, setList] = useState({});
    const [userError, setUserError] = useState({});
    const runLink = generateWebLink(list, false, "run-list");

    useEffect(
        () => {
            // user id
            const userID = props.match.params.uid;
            const listID = props.match.params.lid;
            if (!userID || !listID) {
                setUserError(
                    {
                        title: "URL is invalid",
                        message: "Missing either, user id, or list id"
                    }
                );
                return;
            }

            // TODO: combine logic for results && aggregated results for getting of the list, perhaps a component?
            // or export function
            getList(userID, listID)
                .then(
                    (list) => {
                        // TODO: If deleted then show error
                        if (!list || list.isDeleted) {
                            setUserError(
                                {
                                    title: "List is invalid",
                                    message: "It doesn't exist, is deleted, or is corrupted"
                                }
                            );
                            return;
                        }

                        const ranker = new Ranker(list);
                        
                        setList(list);
                        setOutput(ranker.output());
                    }
                )
                .catch(
                    (error) => {
                        // TODO: exit here
                        console.error(error);
                    }
                );
        }, []
    );

    const toFixedWithoutRounding = (n, p) => {
        const result = n.toFixed(p)

        return result <= n ? result: (result - Math.pow(0.1, p)).toFixed(p)
    }

    const handleToggleResults = () => {
        setShowResponses(!showResponses);
        
        if (!showResponses) {
            setShowResponseText("Hide Results")
        } else {
            setShowResponseText("Show Results")
        }
    }

    if (userError && userError.title && userError.message) {
        return (
            <div>
                <UserError
                    title={ userError.title }
                    message={ userError.message } />
            </div>
        );
    }

    // TODO: below I have uploaded src, use that to display image as is being done in the list editor, except it is uploaded src
    // should be much easier than before
    return (
        <div>
            <Typography variant="h4" className={ `title container` }>
                Combined Results
            </Typography>
            <Typography variant="h5" className={ `sub-title container` }>
                Which one {list.comparison}?
            </Typography>
            <Grid container spacing={ 4 } alignItems="flex-end" className="list-panel">
                <Grid item>
                    <Button onClick={ handleToggleResults } variant="text" color="primary">
                        {showResponseText}
                    </Button>
                </Grid>
            </Grid>
            <Paper className={ `paper-container center` } elevation={ 6 }>
                <Grid className={ `` } container alignItems="flex-start">
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Item</TableCell>
                                <TableCell>Nuanced Rank</TableCell>
                                {
                                    showResponses && output && output.length > 0 && output.slice(1).map(
                                        (item, index) => {
                                            return (<TableCell key={ item.response.userID }>{ item.response.userName || index }</TableCell>)
                                        }
                                    )
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                output && output.length > 0 && output.slice(0, 1)[0].map(
                                    (obj, index) => {
                                        return (
                                            <TableRow key = { index } >
                                                <TableCell>
                                                    <img src={ obj.row.image.uploadedSrc }/>{ obj.row.text }
                                                </TableCell>
                                                <TableCell>
                                                    { toFixedWithoutRounding(obj.rank.nuanceAvg + 1, 2) }
                                                </TableCell>
                                                {
                                                    showResponses && output.slice(1).map(
                                                        (row) => {
                                                            return (
                                                                <TableCell key={ row.response.userID } >
                                                                    <span className="color-rank" style = {
                                                                        {
                                                                            backgroundColor: `#0000${ (Math.floor(255 * (1 - row.arr[index] / output[0].length))).toString(16) }`,
                                                                            color: "#FFFFFF"
                                                                        }
                                                                    }>
                                                                        { row.arr[index] + 1 }
                                                                    </span>
                                                                </TableCell>
                                                            )
                                                        }
                                                    )
                                                }
                                            </TableRow>
                                        )
                                    }
                                )
                            }
                            { showResponses &&
                                <TableRow>
                                    <TableCell>Std. Deviation from Std. Rank</TableCell>
                                    <TableCell></TableCell>
                                    {
                                        output && output.length > 0 && output.slice(1).map(
                                            (obj, index) => <TableCell key={ index }>{ toFixedWithoutRounding(obj.response.stdDev, 2) }</TableCell>
                                        )
                                    }
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </Grid>
            </Paper>
            <Grid container spacing={ 4 } alignItems="flex-end" className="list-panel">
                { user &&
                    <Grid item>
                        <Button component={ Link } to="/my-lists" variant="text" color="primary">
                            My Lists
                        </Button>
                    </Grid>
                }
                <Grid item>
                    <Button component={ Link } to={runLink} variant="text" color="secondary">
                        Restart
                    </Button>
                </Grid>
            </Grid>
        </div>
    );
    
};

export default AggregatedResults;

// response object, depends on original list
// PERHAPS: replace userID & userID with user object?
export default function ListResponse(created=new Date(), orderedIndexList=[], comparisons=[], userID, userName) {
    this.created = created;
    this.comparisons = comparisons;
    // orderedIndexList is an array of indexes, referrencing back to the order of the original list
    this.orderedIndexList = orderedIndexList;
    this.userID = userID;
    this.userName = userName;
    this.stdDev = 0;

    this.toFirebaseObject = () => {
        return {
            orderedIndexList: this.orderedIndexList,
            comparisons: this.comparisons,
            userName: this.userName
        }
    }
}

export const snapshotDataToListResponse = (data, key) => {
    return new ListResponse(null, data.orderedIndexList, data.comparisons, key, data.userName);
}

import * as firebase from "firebase";
import { config } from "./config/Firebase";

/*
 * Redux action stauses / states
 */

export const FAILURE    = "FAILURE";
export const INCOMPLETE = "INCOMPLETE";
export const PENDING    = "PENDING";
export const SUCCESS    = "SUCCESS";

/*
 * Redux action creator types
 */
export const CREATE_NEW_LIST = "CREATE_NEW_LIST";
export const SIGN_IN         = "SIGN_IN";
export const SIGN_OUT        = "SIGN_OUT";

/*
 * Redux action creators
 */
export const GENERIC_ACTION = (type, status, payload, error) => {
    return {
        type: type,
        payload: {
            ...payload,
            status: status,
            error: error
        }
    };
}

export const GENERIC_FAILURE_ACTION = (type, error) => {
    return GENERIC_ACTION(type, error, {}, FAILURE);
}

/*
 * Firebase
 */
export const FIREBASE_APP = firebase.initializeApp(config);

/*
 * Firebase references
 */
export const ANALYTICS = firebase.analytics();
export const AUTH      = firebase.auth();
export const DATABASE  = firebase.database();
export const STORAGE   = firebase.storage();

/*
    Firebase Event Types
*/
export const EVENT_TYPE_CHILD_ADDED = "child_added"
export const EVENT_TYPE_CHILD_CHANGED = "child_changed"
export const EVENT_TYPE_CHILD_REMOVED = "child_removed"

/*
    Firebase Single Event Types
*/
export const EVENT_TYPE_SINGLE_VALUE = "value"

/*
 *  Auth persistence
 */
AUTH.setPersistence(firebase.auth.Auth.Persistence.LOCAL);

/*
 * Firebase authentication providers
 */
export const FACEBOOK_AUTH_PROVIDER = new firebase.auth.FacebookAuthProvider();
export const GOOGLE_AUTH_PROVIDER   = new firebase.auth.GoogleAuthProvider();

/*
 * Storage references
 */
export const STORAGE_REF = STORAGE.ref();

/*
 * Database references
 */
export const USERS_REF = DATABASE.ref("Users");

/* Regex */
export const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

/* List validation enum */
export const LIST_VALIDATION_ENUM = Object.freeze(
    {
        SUCCESS: [
            0,
            "Successfully validated"
        ],
        EMPTY_ROWS: [
            1,
            "There are empty rows"
        ],
        FEWER_THAN_TWO_ROWS: [
            2,
            "Please add at least 2 rows"
        ],
        IMAGES_COUNT: [
            3,
            "If you have one image, all rows need an image"
        ]
    }
);

import {
    SUCCESS,
    CREATE_NEW_LIST
} from "../../Constants";

const initialState = {
    error: null,
};

export default function(state = initialState, action) {
    switch (action.type) {
        case CREATE_NEW_LIST:
            if (action.payload.status !== SUCCESS)
                return state;

            return state;
        default:
            return state;
    }
}
import { DATABASE, EVENT_TYPE_SINGLE_VALUE, STORAGE_REF, USERS_REF } from "../Constants";

import List, { snapshotDataToList } from "../objects/List";

export const updateUserName = (userID, name) => {
    const ref = USERS_REF.child(`${ userID }`);

    return ref.update(
        {
            name: name
        }
    );
};

// use this function if none of the lists data has been modified
export const saveListWithUpdatedProperties = (list) => {
    const ref = USERS_REF.child(`${ list.ownerID }/lists/${ list.key }`);

    list.modified = Date();

    console.log(list.toFirebaseObject());
    return ref.update(list.toFirebaseObject());
};

export const saveEditList = (list) => {
    const ref = USERS_REF.child(`${ list.ownerID }/lists/${ list.key }`);

    list.modified = Date();
    console.log("obj", list.toFirebaseObject());

    return ref.set(list.toFirebaseObject())
        .then(
            () => {
                return _saveList(list, ref);
            }
        );
};

export const saveNewList = (userID, comparison, name, rows, isTemporary) => {
    const currentDate = Date();
    const ref = USERS_REF.child(`${ userID }/lists`);

    // this is a thenable reference, so in future, make async or use promise so
    // everything happens afterwards
    const list = new List(comparison, currentDate, isTemporary, false, null, currentDate, name, userID, [], rows);
    const listRef = ref.push(list.toFirebaseObject());

    list.key = listRef.key;

    return _saveList(list, listRef);
};

const _saveList = (list, ref) => {
    const promises = [];
    const imageRowsIndexes = [];

    for (let i = 0; i < list.rows.length; ++i) {
        const row = list.rows[i];

        // should never be the case, but if the case the promise all will return
        // in future, we should return earlier, around here
        if (!row.hasImage()) {
            continue;
        }

        promises.push(row.uploadImageToFirebase(list.generateFirebaseImagePathPrefix(), i));
        imageRowsIndexes.push(i);
    }

    if (promises.length === 0) {
        return new Promise(
            (resolve, reject) => {
                resolve(list.key);
            }
        );
    }

    return Promise.all(promises)
        .then(
            (data) => {
                // should not happen, never should enter this if, if does, silent fail
                if (data.length !== imageRowsIndexes.length)
                    return;

                for (let i = 0; i < data.length; ++i) {
                    const index = imageRowsIndexes[i];
                    list.rows[index].image.uploadedSrc = data[i];
                }

                return ref.set(list.toFirebaseObject());
            }
        ).catch(
            (error) => {
                console.log("failed", error);
            }
        )
}

export const updateListResponse = (listID, listOwnerID, response) => {
    const ref = USERS_REF.child(`${ listOwnerID }/lists/${ listID }/responses/${ response.userID }`);
    
    return ref.set(response.toFirebaseObject());
};

export const getLists = (userID) => {
    const ref = USERS_REF.child(`${ userID }/lists`);

    return ref.once(EVENT_TYPE_SINGLE_VALUE)
        .then(
            (snapshot) => {
                const data = snapshot.val();

                if (!data || data.length === 0) {
                    throw new Error("no lists exists for this user");
                }

                const listKeys = Object.keys(data);

                return listKeys.map(
                    (key) => snapshotDataToList(data[key], key, userID)
                );
            }
        )
};

export const getList = (userID, listID) => {
    const ref = USERS_REF.child(`${ userID }/lists/${ listID }`);

    return ref.once(EVENT_TYPE_SINGLE_VALUE)
        .then(
            (snapshot) => {
                const data = snapshot.val();

                if (!data || data.length === 0) {
                    throw new Error("that list does not exist");
                }

                return snapshotDataToList(data, listID, userID);
            }
        )
};

export const deleteList = (list) => {
    const ref = USERS_REF.child(`${ list.ownerID }/lists/${ list.key }`);

    return ref.update(
        {
            isDeleted: true
        }
    );
};

import { blobToFile } from "./File";

export const calculateAspectRatio = (w, h) => {
    return w / h;
}

export const calculateNewWidth = (h1, aspectRatio) => {
    return h1 * aspectRatio;
}

export const calculateNewHeight = (w1, aspectRatio) => {
    return w1 / aspectRatio;
}

export const scaleImage = (filename, image, width = 300) => {
    return new Promise(
        (resolve, reject) => {
            let img = new Image();

            img.onload = () => {
                const canvas = document.createElement("canvas");
                const ctx = canvas.getContext("2d");
                const aspectRatio = calculateAspectRatio(img.width, img.height);
                const newHeight = calculateNewHeight(300, aspectRatio);

                canvas.width = width;
                canvas.height = newHeight;

                ctx.drawImage(img, 0, 0, width, newHeight);
                canvas.toBlob(
                    (blob) => {
                        if (!blob) {
                            reject(new Error("canvas is empty"));
                            return;
                        }

                        // TODO: use filename from user, or id
                        blob = blobToFile(blob, filename);
                        resolve(blob);

                        ctx.clearRect(0, 0, canvas.width, canvas.height);
                        canvas.remove();
                    }
                )
            }

            img.src = image;
        }
    );
}

import React from "react";

import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

import Delete from "@material-ui/icons/Delete"
import Edit from "@material-ui/icons/Edit"
import Share from "@material-ui/icons/Share";

import { Link } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { generateWebLink } from "../../utility/Link";

// TODO: fix link, they are absolute, but to is relative
// TODO: decide if edit link requires uid, or for consistency
function MyListsRow(props) {
    const { list, uid } = props;

    const startLink = `/run-list/${list.ownerID}/${list.key}`;

    const hasResponses = list.hasResponses();

    const aggregatedResultsLink = generateWebLink(list,false, "aggregated-results");
    const editLink = generateWebLink(list, false, "edit");
    const resultsLink = `${ generateWebLink(list, false, "results") }/${ uid }`;
    const runLink = generateWebLink(list, false, "run-list");
    const shareRunLink = generateWebLink(list, true, "run-list");

    const handleCopy = () => {
        if (props.onCopy)
            props.onCopy();
    };

    const handleDelete = () => {
        if (props.onDelete)
            props.onDelete(list.key);
    }

    return (
        <TableRow>
            <TableCell>
                { hasResponses &&
                    (
                        <div>
                            <Link to={ aggregatedResultsLink }>
                                <b>{ list.name }</b>
                            </Link>
                            <br />
                            <br />
                            <Link to={ resultsLink }>Your response</Link>
                        </div>
                    )
                    ||
                    (
                        <div>
                            <span>
                                <b>{ list.name }</b>
                            </span>
                            <br />
                            <Link to={ runLink }>
                                Prioritize
                            </Link>
                        </div>
                    )
                }
            </TableCell>
            <TableCell>
                { hasResponses && list.responses.length || <i>no responses yet</i>}
            </TableCell>
            <TableCell>
                <Button component={ Link } to={ startLink } color="primary">
                    Prioritize
                </Button>
            </TableCell>
            <TableCell>
                <CopyToClipboard onCopy={ handleCopy } text={ shareRunLink } >
                    <IconButton
                        tooltip="Copy"
                        className={ `icon-button` }>
                        <Share color="primary" />
                    </IconButton>
                </CopyToClipboard>

                <Link to={ !hasResponses && editLink || '#' }>
                    <IconButton tooltip="Edit" className={ `icon-button` } title={ hasResponses && "Editing has been disabled because list has responses" || "" }>
                        <Edit color={ !hasResponses && "primary" || "disabled" } />
                    </IconButton>
                </Link>

                <IconButton color="primary" onClick={ handleDelete } variant="text">
                    <Delete color="secondary" />
                </IconButton>
            </TableCell>
        </TableRow>
    );

};

export default MyListsRow;

import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { signInSuccessAction, signOutSuccessAction } from "./state/actions/AuthActions";

import { makeStyles } from "@material-ui/core/styles";

import AuthenticatedAppBar from "./components/app_bar/authenticated/AuthenticatedAppBar";
import DefaultAppBar from "./components/app_bar/default/DefaultAppBar";
import FeedbackBtn from "./components/feedback_btn/feedbackBtn";

import AuthenticatedRouter from "./router/authenticated/AuthenticatedRouter";
import DefaultRouter from "./router/default/DefaultRouter";
import { ANALYTICS, AUTH } from "./Constants";

const useStyles = makeStyles(
    theme => (
        {
            root: {
                flexGrow: 1,
            }
        }
    )
);

// Google Analytics already logs certain events for you
// See: https://support.google.com/firebase/answer/6317485
// this function adds more events to be logged
function setupAnalytics() {
    ANALYTICS.logEvent("sign_up");
    ANALYTICS.logEvent("login");
    ANALYTICS.logEvent("page_view");
}

function Root() {

    setupAnalytics();

    const classes = useStyles();
    const dispatch = useDispatch();

    const user = useSelector(state => state.auth.user);

    AUTH.onAuthStateChanged(
        (user) => {
            if (user) {
                if (user.displayName) {
                    dispatch(signInSuccessAction(user));
                } else {
                    setTimeout(
                        function() {
                            dispatch(signInSuccessAction(AUTH.currentUser));
                        },
                        1000
                    );
                }
            } else {
                dispatch(signOutSuccessAction());
            }
        }
    );

    if (user) {
        return (
            <div className={ classes.root }>
                <AuthenticatedAppBar displayName={ user.displayName } />
                <AuthenticatedRouter />
                <FeedbackBtn />
            </div>
        );
    } else {
        return (
            <div className={ classes.root }>
                <DefaultAppBar />
                <DefaultRouter />
                <FeedbackBtn />
            </div>
        );
    }

}

export default Root;

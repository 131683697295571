export const generateWebLink = (list, absolute=true, page="run-list") => {
    const local = `/${ page }/${ list.ownerID }/${ list.key }`;

    if (!absolute) {
        return local;
    }

    const noProtocol = window.location.href.replace(window.location.protocol, '').substring(2);
    const pre = noProtocol.substring(0, noProtocol.indexOf('/'));
    const link = `${ window.location.protocol }//${ pre }${ local }`;

    return link;
}